<template>
  <div id="app">
    <section id="main-menu" v-bind:class="{'hide': showActiveImages}">
      <img src="./assets/image_1.png" />
      
      <span class="link link-1" v-on:click="activeContent(1)"></span>
      <span class="link link-2" v-on:click="activeContent(2)"></span>
      <span class="link link-3" v-on:click="activeContent(3)"></span>
      <span class="link link-4" v-on:click="activeContent(4)"></span>
      <span class="link link-5" v-on:click="activeContent(5)"></span>
      
      <span class="modal-link" v-on:click="showModal('image_16');"></span>
      <span class="modal-link-2" v-on:click="showModal('image_16');"></span>
    </section>
    
    <section id="active-images" v-bind:class="{'active': showActiveImages}">
      <span class="home-link" v-on:click="activeContent(0);"></span>
      <span class="modal-link" v-on:click="showModal('image_16');"></span>
      <span class="references-link" v-on:click="showModal('image_15');"></span>
      
      <div v-for="image in activeImages" class="item">
        <img :src="require(`@/assets/${image}.png`)" />
      </div>
    </section>
    
    <section id="modal-dialog" v-bind:class="{'active': modalActive}">
      <span class="close" v-on:click="showModal()"></span>
      
      <img v-if="modalImage" :src="require(`@/assets/${modalImage}.png`)" />
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: function () {
    return {
      modalActive: false,
      modalImage: null,
      showActiveImages: false,
      activeImages: []
    }
  },
  methods: {
    showModal: function(image) {
      if (image) {
        this.modalActive = true;
        this.modalImage = image;
      } else {
        this.modalActive = false;
      }
    },
    activeContent: function (index) {
      this.showActiveImages = true;
      
      if (index === 0) {
        this.showActiveImages = false;
      } else if (index === 1) {
        this.activeImages = ['image_2', 'image_12'];
      } else if (index === 2) {
        this.activeImages = ['image_6', 'image_7', 'image_8'];
      } else if (index === 3) {
        this.activeImages = ['image_9', 'image_10', 'image_11', 'image_13'];
      } else if (index === 4) {
        this.activeImages = ['image_3', 'image_4', 'image_5'];
      } else if (index === 5) {
        this.activeImages = ['image_14'];
      }
      
      if (index > 0) {
        document.querySelector('#active-images').scrollLeft = 0;
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: white;
  opacity: 1;
  transition: 220ms opacity ease-in;
}

#main-menu.hide {
  transition: 220ms opacity ease-in 400ms;
  opacity: 0;
}

#main-menu .link {
  position: absolute;
  width: 14%;
  height: 10%;
  top: 1%;
}

#main-menu .link-1 {
  left: 2.5%;
}

#main-menu .link-2 {
  left: 17.5%;
}

#main-menu .link-3 {
  left: 32.5%;
}

#main-menu .link-4 {
  left: 48%;
}

#main-menu .link-5 {
  left: 63%;
}

#main-menu img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

#main-menu .modal-link-2,
#main-menu .modal-link {
  position: fixed;
  width: 380px;
  height: 240px;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 100;
}

#main-menu .modal-link-2 {
  left: 0;
  right: auto;
}

#active-images .references-link {
  position: fixed;
  width: 180px;
  height: 60px;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 100;
}

#active-images {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;  
  -webkit-scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  -webkit-scroll-snap-points-x: repeat(100%);
  scroll-snap-points-x: repeat(100%);
  transition: 220ms opacity ease-in;
}

#active-images.active {
  opacity: 1;
  pointer-events: initial;
}

#active-images .home-link {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 90px;
  height: 90px;
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  z-index: 200;
}

#active-images .home-link:after {
  content: '';
  border: 2px solid white;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

#active-images .home-link:before {
  content: '';
  border: 2px solid white;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

#active-images .modal-link {
  position: fixed;
  width: 420px;
  height: 200px;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 100;
}

#active-images .item {
  scroll-snap-align: start;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #F0F0F0;
  display: inline-block;
}

#active-images .item img {
  object-fit: contain;
  height: 100%;
}

#modal-dialog {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  transform: scale(1.3);
  opacity: 0;
  transition: all 210ms ease-in;
  pointer-events: none;
}

#modal-dialog.active {
  pointer-events: initial;
  opacity: 1;
  transform: scale(1);
}

#modal-dialog .close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 90px;
  height: 90px;
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
}

#modal-dialog .close:after {
  content: '';
  border: 2px solid white;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

#modal-dialog .close:before {
  content: '';
  border: 2px solid white;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

#modal-dialog img {
  width: 100%;
}
</style>
